import Vue from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
import store from "./store";
import ElementUI from 'element-ui';
import http from '@/utils/request'
import { mixins } from "./utils/mixin.js";
import 'element-ui/lib/theme-chalk/index.css';
import '@/style/style.scss'
import './assets/iconfont/iconfont.css';
import 'zico/scss/zico.scss'

Vue.config.productionTip = false
Vue.prototype.$http = http
Vue.mixin(mixins);
Vue.use(ElementUI);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
