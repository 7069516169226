export const mixins = {
  data() {
    return {
      count: 0,
      pageNo: 1,
      pageRow: 12
		};
  },
  created() {},
  mounted() {
    this.scrollToTop();
  },
  methods: {
    scrollToTop(){
      window.scrollTo(0, 0);
    }
  },
};