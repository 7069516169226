<template>
  <div id="app">
    <head-con></head-con>
    <keep-alive :include="hasView">
      <router-view :key="key" />
    </keep-alive>
    <footer-con></footer-con>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import headCon from "@/components/header";
import footerCon from "@/components/footer";
export default {
  data() {
    return {
      headerShow: false,
      footerShow: false,
      key: ''
    };
  },
  computed: {
    ...mapGetters(["hasView"]),
  },
  watch: {
    $route(to) {
      this.key = to.fullPath;
    },
  },
  mounted() {
  },
  methods: {
  },
  components: {
    headCon,
    footerCon,
  },
};
</script>
<style lang="scss">
</style>
