import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home/Home.vue";

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
   return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: "/",
    name: "Home",
    meta: {
      hasBanner: true
    },
    component: () =>
      import("@/views/home/Home.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/news/news.vue"),
  },
  {
    path: "/news2",
    name: "News2",
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/news/news2.vue"),
  },
  {
    path: "/news3",
    name: "News3",
    meta: {
      hasBanner: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/news/news3.vue"),
  },
  {
    path: "/newsDetail",
    name: "newsDetail",
    component: () =>
      import("@/views/newsDetail/newsDetail.vue"),
  },
  {
    path: "/detail",
    name: "Detail",
    component: () =>
      import("@/views/newsDetail/detail.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
