<template>
  <div class="home">
    <el-row>
      <el-col :span="24">
        <div class="banner">
          <swiper :options="swiperOptions" v-if="bannerList.length">
            <swiper-slide v-for="(item, index) in bannerList" :key="index">
              <img :src="item.imgurl" alt="" />
              <div class="banner_tit">{{ item.mc }}</div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
            <div class="swiper-button-prev" slot="button-prev"></div>
            <div class="swiper-button-next" slot="button-next"></div>
          </swiper>
          <!-- <div class="more">
            <span>|</span>
            <span class="cli">更多></span>
          </div> -->
        </div>
      </el-col>
    </el-row>
    <!-- 媒体万谋 -->
    <div class="media">
      <div class="med-inner m_flex_box flex_vw flex_jz">
        <div class="tab flex_2">
          <a class="media_prev"></a>
          <a class="media_next"></a>
          <swiper :options="swiperOptions1" v-if="newsList3.length">
            <swiper-slide
              v-for="(item, index) in newsList3"
              :key="index"
              :data-idx="item.id"
            >
              <div class="tab_img"><img :src="item.cover" :data-idx="item.id"/></div>
              <div class="new" :data-idx="item.id">
                <div class="new_tit">
                  <span :data-idx="item.id">头条新闻 HEADLINES</span>
                </div>
                <div class="big_tit line_comp2" :data-idx="item.id">
                  {{ item.title }}
                </div>
                <div class="inner_tit line_comp4" :data-idx="item.id">
                  {{ item.synopsis }}
                </div>
              </div>
            </swiper-slide>
            <div class="swiper-pagination" slot="pagination"></div>
          </swiper>
        </div>
        <div class="titlist flex_1">
          <p>媒体万谋 IN THE WANMOU</p>
          <div
            v-for="(item, index) in newsList1"
            :key="index"
            @click="goDetail(item.id)"
          >
            <div class="oneNews" v-if="index == 0">
              <div class="imgbox">
                <img :src="item.cover" alt="" />
              </div>
              <div class="onetit line_comp2">{{ item.title }}</div>
            </div>
            <ul v-else>
              <li>
                <div class="line_comp2">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div class="titlist flex_2">
          <p>专题推荐 HIGHLIGHTS</p>
          <div
            v-for="(item, index) in newsList2"
            :key="index"
            @click="goDetail(item.id)"
          >
            <div class="oneNews" v-if="index == 0">
              <div class="imgbox">
                <img :src="item.cover" alt="" />
              </div>
              <div class="onetit line_comp2">{{ item.title }}</div>
            </div>
            <ul v-else>
              <li>
                <div class="line_comp2">
                  {{ item.title }}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 律所看点 -->
    <div class="index-events">
      <div class="wal m_flex_box flex_vc flex_jz">
        <div class="m_flex_box flex_vc flex_jz">
            <div class="title">
            <div>EVENTS</div>
            <h2>律所看点</h2>
          </div>
          <p class="none">
            {{ lskdjj }}
          </p>
        </div>
        <!-- <div class="events_more">
          <span>查看更多</span>
        </div> -->
        <div class="arrow none" v-if="newsList4.length > 5">
          <div class="prev prev_" @click="prev"></div>
          <div class="next next_" @click="next"></div>
        </div>
      </div>
      <div class="list">
        <div class="list_wrapper">
          <swiper :options="swiperOptions2" ref="swiper2" v-if="newsList4.length">
            <swiper-slide
              class="ls_slide"
              v-for="(item, index) in newsList4"
              :key="index"
            >
              <!-- <div class="list_title line_comp1" :data-idx="item.id">{{ item.title }}</div> -->
              <div class="list_img">
                <img :src="item.covermin" alt="" :data-idx="item.id"/>
              </div>
              <div class="wenan line_comp1" :data-idx="item.id">{{ item.title }}</div>
              <div class="info">
                <p class="m_flex_box flex_vc">
                  <img src="../../assets/home/sj.png" :data-idx="item.id"/><span :data-idx="item.id">{{
                    item.create_date | format
                  }}</span>
                </p>
                <p class="m_flex_box flex_vc">
                  <img src="../../assets/home/dw.png" /><span :data-idx="item.id"
                    >万谋律师事务所</span
                  >
                </p>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </div>
    </div>
    <!-- 行业创新 -->
    <div class="index_research">
      <div class="wal">
        <div class="title">
          <div>INDUSTRY INNOVATION</div>
          <h2>行业创新</h2>
        </div>
        <div class="content none">
          {{ hycx }}
        </div>
        <div class="listbox none">
          <div class="list">
            <ul>
              <li
                v-for="(item, index) in newsList5"
                :key="index"
                @mouseover="addClass(index)"
                :class="hoverActive == index ? 'on' : ''"
                @click="goDetail(item.id)"
              >
                <a class="name line_comp2" :title="item.title">
                  {{ item.title }}
                </a>
              </li>
            </ul>
          </div>
        </div>
        <!-- 小于800显示 -->
        <ul>
          <li
            class="imgbox"
            v-for="(item, index) in newsList5"
            :key="index"
            @mouseover="addClass(item)"
            :class="hoverActive == item ? 'on' : ''"
            @click="goDetail(item.id)"
          >
            <div class="img">
              <img :src="item.cover" alt="" />
              <p>{{ item.title }}</p>
            </div>
          </li>
        </ul>
      </div>
      <div class="big_img none">
        <p>
          <img :src="hoverImg" alt="" />
        </p>
      </div>
    </div>
    <!-- 加入万谋 -->
    <div class="jion">
      <div class="title">
        <div>JOIN WANMOU</div>
        <h2>加入万谋</h2>
      </div>
      <div class="content">
        {{ addwanmou }}
      </div>
      <div class="list">
        <swiper :options="swiperOptions3">
          <swiper-slide
            v-for="(item, index) in zhiweiList"
            :key="index"
            class="m_flex_box flex_ar flex_vw"
          >
            <div v-for="(t, i) in item" :key="i" class="jionList">
              <div class="list_img">
                <img :src="t.logo" alt="" />
              </div>
              <div class="num">{{ t.rs }}</div>
              <div class="level">{{ t.name }}</div>
            </div>
          </swiper-slide>
          <div class="jion-pagination" slot="pagination"></div>
        </swiper>
      </div>
    </div>
    <!-- 关注 -->
    <div class="index_flow">
      <div class="wal">
        <div class="container">
          <!-- 小于800PX -->
          <div class="imgbox sm">
            <div class="video">
            <div id="dplayer_" @click="change"></div>
          </div>
          </div>
          <div class="list">
            <ul>
              <router-link :to="item.path" v-for="(item, index) in otherList" :key="index">
                <li>
                  <div>
                    <span>{{ item.title }}</span>
                    <i class="iconfont">&#xe67f;</i>
                  </div>
                </li>
              </router-link>
            </ul>
          </div>
          <div class="video none">
            <div id="dplayer" @click="change"></div>
          </div>
        </div>
        <div class="title">
          <div>FOLLOW @SOCIAL MEDIA</div>
          <h2>关注社交媒体上的我们</h2>
          <!-- <span>查看更多</span> -->
          <dl class="m_flex_box">
            <dd>
              <div class="icon">
                <img src="../../assets/home/icon_dy.png" alt="" />
              </div>
              <div class="layer">
                <div class="ewm">
                  <div class="img">
                    <img
                      :src="dyewm"
                      alt=""
                    />
                  </div>
                  <div class="name">万谋律所抖音号</div>
                </div>
              </div>
            </dd>
            <dd>
              <div class="icon">
                <img src="../../assets/home/icon_wb.png" alt="" />
              </div>
              <div class="layer">
                <div class="ewm">
                  <div class="img">
                    <img
                      :src="wbewm"
                      alt=""
                    />
                  </div>
                  <div class="name">万谋律所微博号</div>
                </div>
              </div>
            </dd>
            <dd>
              <div class="icon">
                <img src="../../assets/home/icon_zh.png" alt="" />
              </div>
              <div class="layer">
                <div class="ewm">
                  <div class="img">
                    <img
                      :src="zhewm"
                      alt=""
                    />
                  </div>
                  <div class="name">万谋律所知乎号</div>
                </div>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <div class="img_list">
        <ul>
          <li v-for="(item, index) in newsList6" :key="index" @click="toOther(item.author)">
            <div class="img_box">
              <div class="img">
                <img class="main_img" :src="item.cover" />
              </div>
              <div class="layer">
                <div class="time">{{item.create_date | format}}</div>
                <p>{{item.title}}</p>
                <div class="info info-2">
                  <!-- <img src="../../assets/home/img-wx.png" alt="" /> -->
                  <span>{{ item.TYPE }}</span>
                </div>
              </div>
            </div>
          </li>
          <!-- <li>
            <div class="img_box">
              <div class="img">
                <img
                  class="main_img"
                  src="https://www.tsinghua.edu.cn/__local/6/96/0E/E4EBE4A747D01D138FE06971AAA_1049B511_17A66.jpg"
                />
              </div>
              <div class="layer">
                <div class="time">11月06日 19:06</div>
                <p>共赏清华园之秋</p>
                <div class="info info-2">
                  <img src="../../assets/home/img-wx.png" alt="" />
                  <span>微信</span>
                </div>
              </div>
            </div>
          </li>
          <li class="li_01">
            <div class="img_box">
              <div class="img">
                <img
                  class="main_img"
                  src="https://www.tsinghua.edu.cn/__local/1/AC/C1/464EC382C48F3F989EB59E1275E_F60035EB_F483.jpg"
                />
              </div>
              <div class="layer">
                <div class="time">11月06日 19:06</div>
                <p>共赏清华园之秋</p>
                <div class="info info-2">
                  <img src="../../assets/home/img-wx.png" alt="" />
                  <span>微信</span>
                </div>
              </div>
            </div>
          </li>
          <li class="li_01">
            <div class="img_box">
              <div class="img">
                <img
                  class="main_img"
                  src="https://www.tsinghua.edu.cn/__local/9/B1/00/3FE4EC4A455F4A7492E36B4F116_E0BED128_2F7A3.jpg"
                />
              </div>
              <div class="layer">
                <div class="time">11月06日 19:06</div>
                <p>共赏清华园之秋</p>
                <div class="info info-2">
                  <img src="../../assets/home/img-wx.png" alt="" />
                  <span>微信</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="img_box">
              <div class="img">
                <img
                  class="main_img"
                  src="https://www.tsinghua.edu.cn/__local/6/27/AF/F73A5A887A9D7AA587B98602264_B84CFFA1_6B3EE.jpg"
                />
              </div>
              <div class="layer">
                <div class="time">11月06日 19:06</div>
                <p>共赏清华园之秋</p>
                <div class="info info-2">
                  <img src="../../assets/home/img-wx.png" alt="" />
                  <span>微信</span>
                </div>
              </div>
            </div>
          </li>
          <li>
            <div class="img_box">
              <div class="img">
                <img
                  class="main_img"
                  src="https://www.tsinghua.edu.cn/__local/D/F0/64/E715FFCB300330EC2DE8D521548_E15604A3_2FF0C.jpg"
                />
              </div>
              <div class="layer">
                <div class="time">11月06日 19:06</div>
                <p>共赏清华园之秋</p>
                <div class="info info-2">
                  <img src="../../assets/home/img-wx.png" alt="" />
                  <span>微信</span>
                </div>
              </div>
            </div>
          </li> -->
        </ul>
      </div>
    </div>
    <!-- 底部链接 -->
    <div class="wal foot_link">
      <div class="title">数字化项目</div>
      <div class="list">
        <ul class="m_flex_box">
          <li v-for="(item, index) in digitizationList" :key="index" @click="goDetail(item.id)">
            <span>{{ item.title }}</span>
          </li>
        </ul>
      </div>
      <div class="ewm">
        <img :src="gzhewm" />
        <p>欢迎关注万谋律所公众号</p>
      </div>
    </div>
  </div>
</template>

<script>
import qs from "qs";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.min.css";
import DPlayer from "dplayer";
import { formatDate } from "../../utils/Date";
export default {
  name: "Home",
  data() {
    const self = this;
    return {
      index: 0,
      hoverActive: 0,
      flag: false,
      flag1: false,
      changeplay: false,
      dp: null,
      gzhewm: "",
      dyewm: '',
      zhewm: '',
      wbewm: '',
      addwanmou: "",
      sp: "",
      hycx: "",
      lskdjj: "",
      bannerList: [],
      newsList1: [],
      newsList2: [],
      newsList3: [],
      newsList4: [],
      newsList5: [],
      newsList6: [],
      digitizationList: [],
      zhiweiList: [],
      otherList: [],
      footImg: [],
      hoverImg: "",
      left: require("../../assets/home/right.png"),
      date: new Date(),
      swiperOptions: {
        autoplay: {
          delay: 8000,
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        // loop: true,
        effect: "fade",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
      },
      swiperOptions1: {
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        navigation: {
          nextEl: ".media_next",
          prevEl: ".media_prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "bullets",
          clickable: true,
        },
        on: {
          click:  (e) => {
            console.log(self)
            let id = e.target.getAttribute("data-idx");
            if(id) {
              self.$router.push({
                path: '/newsDetail',
                query: {
                  id
                }
              })
            }
          },
        },
      },
      swiperOptions2: {
        effect: "",
        loop: true,
        autoplay: {
          delay: 8000,
          disableOnInteraction: false, //用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        slidesPerView: "auto",
        loopedSlides: 7,
         on: {
          click:  (e) => {
            console.log(e.target.getAttribute("data-idx"))
            let id = e.target.getAttribute("data-idx");
            self.$router.push({
              path: '/newsDetail',
              query: {
                id
              }
            })
          },
        },
      },
      swiperOptions3: {
        loop: true,
        speed: 2500,
        spaceBetween: 30,
        centeredSlides: true,
        watchSlidesProgress: true,
        pagination: {
          el: ".jion-pagination",
          type: "bullets",
          clickable: true,
        },
      },
    };
  },
  created() {},
  mounted() {
    this.getBanner();
    this.getNews();
    this.getZhiWei();
    this.getEwm();
    this.getOtherUrl();
    // 向页面添加股东事件
    window.addEventListener(
      "scroll",
      (this.handleScroll = this.handleScroll.bind(this))
    );
  },
  beforeDestroy() {
    //离开这个界面之后，删除滚动事件，不然容易除bug
    window.removeEventListener("scroll", this.handleScroll);
  },
  filters: {
    format(value) {
      var date = new Date(value);
      var tt = [date.getFullYear(), date.getMonth() + 1, date.getDate()].join(
        "-"
      );
      return tt;
    },
  },
  methods: {
    toOther(url) {
      window.open(url)
    },
    handleClickCard(e) {
      let cardDataJSON = e.target.getAttribute('data-card')
      console.log(e.target.dataset.card, e.target.getAttribute('data-card'))
      let cardData = JSON.parse(cardDataJSON)
      console.log(cardData)
    },
    prev() {
      this.$refs.swiper2.swiper.slidePrev()
    },
    next() {
      this.$refs.swiper2.swiper.slideNext()
    },
    goDetail(id) {
      this.$router.push("/newsDetail?id=" + id);
    },
    getBanner() {
      this.$http
        .get("api.do?call&code=40289fd48649f4da01864db8befd0014", {
          params: {},
        })
        .then((res) => {
          this.bannerList =
            res.result["40289fd48649f4da01864db8befd0014"] || [];
        });
    },
    getNews() {
      const box = [
        {
          maxtype: "40289fd48653196901865322b4280007",
          type: "01",
          listName: "newsList1",
          ROWS: "6",
        },
        {
          maxtype: "2c97ac788bf0c06c018bf5b007dd0025",
          type: "",
          listName: "newsList6",
          ROWS: "6",
        },
        {
          maxtype: "40289fd48653196901865322b4280007",
          type: "02",
          listName: "newsList2",
          ROWS: "6",
        },
        {
          maxtype: "40289fd48653196901865322b4280007",
          type: "03",
          listName: "newsList3",
          ROWS: "5",
        },
        {
          maxtype: "40289fd48653196901865325a2fc000c", //律所
          type: "21",
          listName: "newsList4",
          ROWS: "20",
        },
        {
          maxtype: "40289fd48653196901865325dccb000d", //行业创新
          type: "11",
          listName: "newsList5",
          ROWS: "6",
        },
        {
          maxtype: "40289fd486545dbc0186546888f60012", //数字化项目
          type: "",
          listName: "digitizationList",
          ROWS: "5",
        },
      ];
      for (let i of box) {
        this.getAllNews(i.type, i.maxtype, i.listName, i.ROWS);
      }
    },
    getAllNews(type, maxtype, listName, ROWS) {
      this.$http
        .post(
          "api.do?call&code=40289fd4864fba2301864fd31083000d",
          qs.stringify({
            order: " order by create_date desc",
            PAGE: "1",
            ROWS,
            type,
            maxtype,
          })
        )
        .then((res) => {
          let newsList = res.result["40289fd4864fba2301864fd31083000d"];
          for (let i = 0; i < newsList.rows.length; i++) {
            this[listName].push(newsList.rows[i]) || [];
          }
          if (listName == "newsList5") {
            this.addClass(0);
          }
        });
    },
    getOtherUrl() {
      this.$http.post('api.do?callOne&code=40289fd48bdbfe66018bdc03cf2e0007').then(res => {
        this.otherList = res.result
      })
    },
    addClass(e) {
      this.hoverActive = e;
      let arr = [];
      this.newsList5.forEach((item) => {
        arr.push(item.cover);
      });
      if (this.hoverActive == 0) {
        this.hoverImg = arr[0];
      }
      this.hoverImg = arr[e];
    },
    //滚动事件
    handleScroll() {
      let this_ = this;
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      var offsetTop = document.querySelector(".index_flow").offsetTop;
      if (this.flag1 == false) {
        // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
        if (scrollTop > offsetTop - 1000 && scrollTop < offsetTop + 600) {
          if (!this.flag) {
            this.flag = true;
            this.changeplay = false;
            this_.showVideo();
          }
        } else {
          this.changeplay = false;
          this.flag = false;
          this_.stopVideo();
        }
      }
    },
    initVideo() {
      this.dp = new DPlayer({
        container: document.getElementById("dplayer"),
        volume: 0,
        video: {
          url: this.sp || "",
          // pic: "https://img1.wxzxzj.com/vpc-example-cover-your-name-c.png",
          type: "auto",
        },
      });
      if(this.sp) {
        this.dp.volume(0, false, false);
        this.dp.on("pause", () => {
          if (this.changeplay) {
            this.flag1 = true;
          }
        });
      }
      this.dp = new DPlayer({
        container: document.getElementById("dplayer_"),
        volume: 0,
        video: {
          url: this.sp || "",
          // pic: "https://img1.wxzxzj.com/vpc-example-cover-your-name-c.png",
          type: "auto",
        },
      });
      if(this.sp) {
        this.dp.volume(0, false, false);
        this.dp.on("pause", () => {
          if (this.changeplay) {
            this.flag1 = true;
          }
        });
      }
    },
    showVideo() {
      this.dp.play();
    },
    stopVideo() {
      this.dp.pause();
    },
    change() {
      this.changeplay = true;
    },
    arrPush(list) {
      var arr = []; // 定义外面的数组
      for (var i = 0; i < list.length; i++) {
        // 循环遍历
        if (i % 5 == 0) {
          // 每当余数为 1 时，就重新建立数组，
          var a = [];
          // 将数组存到外面的数组中
          arr.push(a);
        }
        // 将元素存到里面的数组中
        a.push(list[i]);
      }
      this.zhiweiList = arr;
    },
    getZhiWei() {
      this.$http
        .get("api.do?call&code=40289fd486545dbc018654677a040011", {
          params: {},
        })
        .then((res) => {
          this.arrPush(res.result["40289fd486545dbc018654677a040011"] || []);
        });
    },
    getEwm() {
      let arr = [
        {
          title: "gzhewm",
        },
        {
          title: "addwanmou",
        },
        {
          title: "sp",
        },
        {
          title: "hycx",
        },
        {
          title: "lskdjj",
        },
        {
          title: "gzhewm",
        },
        {
          title: "wbewm",
        },
        {
          title: "zhewm",
        },{
          title: "dyewm",
        },
      ];
      for (let i of arr) {
        this.$http
          .get(
            `api.do?callOne&code=40289fd48649f4da01864e0890750022&val=${i.title}`,
            {
              params: {},
            }
          )
          .then((res) => {
            this[i.title] = res.result.txt;
            if (i.title == "sp" && this[i.title]) {
              this.initVideo();
            }
          });
      }
    },
  },
  components: {
    swiper,
    swiperSlide,
    formatDate,
  },
};
</script>
<style lang="scss" scoped>
@import "Home.scss";
</style>
