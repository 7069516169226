import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    routerView: []
  },
  getters: {
    hasView: state => {
      return state.routerView
    },
  },
  mutations: {
    SET_VIEW: (state, view) => {
      state.routerView = view
    },
  },
  actions: {
    saveView({commit}, data) {
      return new Promise(resolve => {
        commit('SET_VIEW', data)
        resolve()
      })
    },
  },
  modules: {
  }
})
