<template>
  <div>
    <div class="footer">
      <div class="wal">
        <div class="logo">
          <img :src="logo" alt="" />
        </div>
        <div class="list">
          <ul class="ul">
            <li>
              <i class="iconfont">&#xe678;</i>
              <p>{{phone}}</p>
            </li>
            <li>
              <i class="iconfont">&#xe918;</i>
              <p>{{email}}</p>
            </li>
            <li>
              <i class="iconfont">&#xe624;</i>
              <p>{{address}}</p>
            </li>
            <!-- <li>
              <i class="iconfont">&#xe60e;</i>
              <p>意见反馈</p>
            </li> -->
          </ul>
        </div>
        <div class="info m_flex_box flex_jz">
          <div class="m_flex_box left">
            <p>版权所有{{bqsy}}</p>
            <p>{{copyright}}</p>
            <a target="_blank" href="https://beian.miit.gov.cn">{{ icpnum }}</a>
          </div>
          <div class="right">
            <div class="right_inner m_flex_box flex_vc">
              <i class="iconfont">&#xe8c7;</i> <span>{{ views }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: "",
      phone: "",
      email: "",
      address: "",
      gzhewm:'',
      bqsy:'',
      icpnum:'',
      copyright:'',
      views: ''
    };
  },
  mounted() {
    this.getLogo();
    this.getViews();
  },
  methods: {
    getViews() {
      this.$http
        .get("api.do?callOne&code=40289fd48bb232c2018bb248c0650002")
        .then((res) => {
          console.log(res)
          this.views = res.result.vc
        });
    },
    getLogo() {
      let arr = [
        {
          title: "logo",
        },
        {
          title: "phone",
        },
        {
          title: "email",
        },
        {
          title: "address",
        },
        {
          title: "bqsy",
        },
        {
          title: "icpnum",
        },
        {
          title: "copyright",
        },
      ];
      for (let i of arr) {
        this.$http
          .get(
            `api.do?callOne&code=40289fd48649f4da01864e0890750022&val=${i.title}`,
            {
              params: {},
            }
          )
          .then((res) => {
            if (i.title == "logo") {
              this.logo = res.result.txt;
            } else if (i.title == "phone") {
              this.phone = res.result.txt;
            } else if (i.title == "email") {
              this.email = res.result.txt;
            } else if (i.title == "address") {
              this.address = res.result.txt;
            } else if (i.title == "gzhewm") {
              this.gzhewm = res.result.txt;
            }else if (i.title == "bqsy") {
              this.bqsy = res.result.txt;
            }else if (i.title == "icpnum") {
              this.icpnum = res.result.txt;
            }else if (i.title == "copyright") {
              this.copyright = res.result.txt;
            }
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wal {
  @include MaxW(1320);
  @include MinH(235);
  margin: 0 auto;
}
.footer {
  position: relative;
  @include Size(12);
  color: #fff;
  &::before {
    content: "";
    display: block;
    position: absolute;
    left: 4.5%;
    bottom: 0;
    right: 0;
    @include Hei(192);
    background: #c6cfd7;
  }
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 7.1%;
    bottom: 0;
    right: 0;
    height: 100%;
    background: rgba(0, 51, 153, 1);
  }
  .wal {
    position: relative;
    .logo {
      position: absolute;
      z-index: 1;
      left: 0;
      right: auto;
      @include Top(46);
      bottom: auto;
      @include Wid(180);
      img {
        @include Wid(180);
      }
    }
    .list {
      position: absolute;
      z-index: 1;
      @include Left(230);
      right: auto;
      @include Top(50);
      bottom: auto;
      @include Wid(350);
      ul {
        display: flex;
        flex-wrap: wrap;
        li {
          @include MarB(10);
          @include PadR(20);
          align-items: center;
          display: flex;
          i {
            @include Size(14);
            @include MarR(10);
          }
        }
      }
    }
    .info {
      position: absolute;
      z-index: 1;
      left: 0;
      right: 0;
      top: auto;
      bottom: 0;
      @include Hei(69);
      border-top: rgba(255, 255, 255, 0.27) solid 1px;
      p,a {
        opacity: 0.36;
        @include LineH(45);
        @include PadR(12);
      }
      .right {
        align-items: center;
        .right_inner {
          i {
            @include MarR(8);
          }
          @include Hei(45);
        }
      }
    }
  }
}
@media screen and (max-width: 800px) {
  .wal {
    width: 100%;
  }
  .footer {
    @include Size(24);
    &::before {
      @include Hei(0);
      z-index: -3;
      left: 0;
    }
    &::after {
      z-index: -2;
      left: 0
    }
    .wal {
      @include Padding(122, 0, 0, 0);
      box-sizing: border-box;

      .logo {
        position: static;
        margin: -10px auto;
      }
      .list {
        position: static;
        width: 100%;
        @include Padding(50, 0, 40, 0);
        ul {
          li {
            width: 100%;
            justify-content: center;
            @include Padding(0,20,0,20);
          }
        }
      }
      .info {
        position: relative;
        height: auto;
        border-top: 0;
        display: block;
        text-align: center;
        &::before {
          content: "";
          display: block;
          position: absolute;
          top: 0;
          width: 80%;
          left: 10%;
          height: 1px;
          background: rgba(255, 255, 255, 0.27);
          z-index: 5;
        }
        .left {
          @include PadT(40);
          // @include Wid(480);
          justify-content: space-around;
          flex-wrap: wrap;
        }
        .right {
          justify-content: space-around;
          @include PadT(40);
        }
      }
    }
  }
}
</style>