<template>
  <div>
    <div class="headerBox">
      <div class="Bline"></div>
      <header>
        <div id="header" class="m_flex_box flex_vc flex_jz">
          <img :src="logo" class="logo" alt="" />
          <div class="nav_right none">
            <div class="nav_top m_flex_box flex_vc flex_he">
              <span class="text" @click="dialogTableVisible = true">留言</span>
              <router-link class="text" to="1">客服</router-link>
              <!-- <div class="login text">
                <router-link to="1">登录</router-link>
                <router-link to="1">注册</router-link>
              </div>
              <i class="zi zi_search" zico="搜索"></i> -->
            </div>
            <div class="nav_con m_flex_box flex_vc">
              <div class="nav_list" :class="{active: $route.path == '/'}">
                <router-link class="span" to="/">首页</router-link>
              </div>
              <div
                class="nav_list"
                :class="{active: ($route.fullPath.indexOf(item.dz) != -1 && item.dz) || parentId == item.id}"
                v-for="(item, index) in navOneList"
                :key="index"
              >
                <span v-if="item.list.length">
                  {{ item.mc }}
                  <i
                    class="zi zi_forDown"
                    zico="下指"
                  ></i
                ></span>
                <router-link :to="item.dz + '&name=' + item.mc" v-else>{{ item.mc }}</router-link>
                <ul v-if="item.list.length">
                  <li v-for="(item_, i) in item.list" :key="i" :class="{active: ($route.fullPath.indexOf(item_.dz) != -1 && item_.dz)}"><router-link :to="item_.dz + '&name=' + item_.mc">{{ item_.mc }}</router-link></li>
                </ul>
              </div>
            </div>
          </div>
          <div class="menu" :class="{ close: isClose }" @click="toggleMenu">
            <img class="open" src="@/assets/menu_open.png" alt="" />
            <img class="close" src="@/assets/menu_close.png" alt="" />
          </div>
          <div class="menu_con" v-if="isClose">
            <ul>
              <li class="one" :class="{active: $route.path == '/'}"><router-link to="/">首页</router-link></li>
              <li class="one" v-for="(item, index) in navOneList" :key="index" :class="{active: ($route.fullPath.indexOf(item.dz) != -1 && item.dz) || parentId == item.id}">
                <span v-if="item.list.length">{{ item.mc }}</span>
                <router-link v-else :to="item.dz + '&name=' + item.mc">{{ item.mc }}</router-link>
                <ul v-if="item.list.length">
                  <li class="two" v-for="(item_, i) in item.list" :key="i" :class="{active: $route.fullPath.indexOf(item_.dz) != -1 && item_.dz}">
                    <router-link :to="item_.dz + '&name=' + item_.mc">{{ item_.mc }}</router-link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </div>
    <div class="header_pad" v-if="!$route.meta.hasBanner">
      <div class="Bline"></div>
      <header>
        <div id="header" class="m_flex_box flex_vc flex_jz">
          <img :src="logo" class="logo" alt="" />
          <div class="nav_right none">
            <div class="nav_top m_flex_box flex_vc flex_he">
              <span class="text" @click="dialogTableVisible = true">留言</span>
            </div>
            <div class="nav_con m_flex_box flex_vc">
              <div class="nav_list">
                <router-link class="span" to="">首页</router-link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
    <el-dialog title="留言" :visible.sync="dialogTableVisible" class="dialog_con" @close="resetForm">
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="姓名" prop="NAME">
          <el-input v-model="form.NAME" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phone">
          <el-input v-model="form.phone" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="留言" prop="context">
          <el-input v-model="form.context" :rows="6" type="textarea" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn_footer" @click="resetForm">取 消</el-button>
        <el-button class="btn_footer" type="primary" @click="formConfirm('ruleForm')">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import qs from 'qs'
export default {
  name: "HelloWorld",
  data() {
    return {
      isClose: false,
      navOneList: [],
      navTwoList: [],
      logo: "",
      parentId: '',
      dialogTableVisible: false,
      form: {
        MANE: '',
        phone: '',
        context: ''
      },
      rules: {
        NAME: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号不正确', trigger: 'blur' }
        ],
        context: [
          { required: true, message: '请输入内容', trigger: 'blur' }
        ],
      },
    };
  },
  props: {
    msg: String,
  },
  watch: {
    $route: {
      handler(n){
        this.isClose = false;
        this.parentId = '';
        this.navOneList.forEach(item => {
          item['40289fd48649f4da01864a19f8d00009'].forEach(inner => {
            if(n.fullPath.indexOf(inner.dz) != -1 && inner.dz){
              this.parentId = inner.mid
            }
          })
        })
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    const element = document.getElementById("header");
    this.height = element.offsetHeight;
    this.getOne();
    this.getLogo();
  },
  methods: {
    formConfirm(formName) {
      this.$refs[formName].validate((valid) => {
          if (valid) {
            this.$http
            .post(
              "api.do?call&code=40289fd48bad84df018bad8819c20000",
              qs.stringify(this.form)
            ).then(() => {
              this.$message.success('提交成功');
              this.resetForm();
            })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
    },
    resetForm() {
      this.$refs['ruleForm'].resetFields();
      this.dialogTableVisible = false;
    },
    toggleMenu() {
      this.isClose = !this.isClose;
    },
    getOne() {
      this.$http
        .get("api.do?call&code=40289fd48649f4da01864a129f9f0008", {
          params: {},
        })
        .then((res) => {
          res.result["40289fd48649f4da01864a129f9f0008"].forEach((item) => {
            item.list = item["40289fd48649f4da01864a19f8d00009"] || [];
          });
          this.navOneList = res.result["40289fd48649f4da01864a129f9f0008"];
          this.parentId = '';
          this.navOneList.forEach(item => {
            item['40289fd48649f4da01864a19f8d00009'].forEach(inner => {
              if(inner.dz == this.$route.fullPath) {
                this.parentId = inner.mid
              }
            })
          })
        });
    },
    getLogo() {
      this.$http
        .get("api.do?callOne&code=40289fd48649f4da01864e0890750022&val=logo", {
          params: {},
        })
        .then((res) => {
          this.logo = res.result.txt;
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.headerBox {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  z-index: 999;
}
.Bline {
  @include Hei(5);
  background-color: #003399;
}
header {
  @include linerBg(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0), top, bottom);
  @include Padding(10, 120, 24, 40);

  .nav_right {
    color: #fff;
  }
  .logo {
    position: relative;
    z-index: 2;
    @include Wid(175);
  }
}
.nav_list {
  @include MarR(23);
  @include PadB(10);
  position: relative;
  &.active{
    color: #003399;
    &:after{
      content: '';
      display: block;
      width: 100%;
      background-color: #003399;
      height: 3px;
      position: absolute;
      left: 0;
      right: 0;
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    &.span,
    > span, >a {
      color: #003399;
      i {
        transition: all ease-in 0.6s;
        color: #003399;
      }
    }
    ul {
      display: block;
      z-index: 1;
    }
  }

  > .span,
  > span, >a {
    @include Size(18);
    font-weight: bold;
    cursor: pointer;
    transition: all ease-in 0.3s;
    &.active {
      color: #003399;
    }
    i {
      @include MarL(8);
      display: inline-block;
      font-weight: 400;
    }
  }
}
.nav_top {
  @include Size(14);
  @include LineH(30);
  .text {
    margin-left: 20px;
    cursor: pointer;
  }
  .login {
    a {
      display: inline-block;
      &:first-child {
        padding-right: 6px;
        margin-right: 6px;
        position: relative;
        &:after {
          content: "";
          display: block;
          width: 1px;
          height: 14px;
          background-color: #fff;
          position: absolute;
          right: 0;
          top: 50%;
          margin-top: -7px;
        }
      }
    }
  }
}
.nav_list {
  ul {
    display: none;
    z-index: -9999;
    transition: all 0.3s;
    border-top: 3px solid #003399;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.2);
    @include Wid(200);
    @include MarL(-100);
    left: 50%;
    background-color: #fff;
    position: absolute;
    list-style: none;
    top: calc(100% - 1px);
    @include PadT(15);
    li {
      color: #003399;
      a{
        display: block;
        @include Padding(15, 30, 15, 30);
      }
      transition: all 0.3s;
      cursor: pointer;
      &:hover,&.active {
        background-color: rgba(0, 0, 0, 0.1);
      }
    }
  }
}
.menu {
  z-index: 2;
  display: none;
  @include Wid(100);
  @include Hei(77);
  background-image: url("../assets/icon_gd.png");
  background-size: cover;
  position: absolute;
  right: 0;
  top: 0;
  color: #fff;
  @include Size(30);
  cursor: pointer;
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    @include MarL(-15);
    @include MarT(-15);
    @include Wid(30);
    @include Hei(30);
    transition: all 0.3s;
    &.close {
      opacity: 0;
    }
    &.open {
      opacity: 1;
    }
  }
  &.close {
    .close {
      opacity: 1;
    }
    .open {
      opacity: 0;
    }
  }
}
@media screen and (max-width: 800px) {
  header {
    @include Padding(30, 30, 30, 30);
    .logo {
      @include Wid(250);
    }
  }
  .menu {
    display: block;
    @include Wid(150);
    @include Hei(calc(150 * 0.77));
    @include Size(34);
  }
}
.header_pad{
  opacity: 0;
  position: relative;
  z-index: -99;
}
.menu_con{
  position: fixed;
  background-color: #003399;
  left: 0;
  right: 0;
  color: #fff;
  top: 0;
  @include PadT(140);
  @include PadL(30);
  @include PadR(30);
  .one{
    @include Padding(20,20,20,20);
    &:not(:last-child){
      border-bottom: 1px solid #f0f0f0;
    }
    &.active{
      font-weight: 600;
    }
  }
  .two{
    @include Padding(10,30,10,30);
    color: rgb(165, 183, 219);
    &.active{
      color: #fff;
      font-weight: 600;
    }
  }
}
.btn_footer{
  @include Wid(60);
  @include Hei(30);
}
@media screen and(max-width: 1024px) {
  /deep/
  .el-dialog{
    width: 80%!important;
  }
  .btn_footer{
  @include Wid(90);
  @include Hei(60);
}
}
</style>
